import React, { useState } from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Box, Grid, Button } from "@material-ui/core"
import rehypeRaw from "rehype-raw"

import Layout from "../components/layout"
import Heading from "../components/heading"
import ProductsModal from "../components/modals/products"

export const query = graphql`
  query ProductQuery($id: String!) {
    strapiProduct(id: { eq: $id }) {
      id
      image {
        publicURL
      }
      name
      content
      cta
      type
      description
      keywordsMeta
    }
  }
`

function Product({ data }) {
  const product = data.strapiProduct
  const [open, setOpen] = useState(false)
  const [selection, setSelection] = useState("")

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Layout
      siteTitle={product.name}
      description={product.description}
      keywords={product.keywordsMeta}
      ogImage={product.image?.publicURL}
    >
      <ProductsModal
        handleClose={handleClose}
        open={open}
        selection={selection}
      />
      <Heading
        title={product.name}
        content={
          <Box fontSize={22}>
            <Typography color="error" component="span">
              <Box fontSize={22} component="span">
                <b>{product.type}</b>
              </Box>
            </Typography>
          </Box>
        }
      />

      <Box py={10} id="genesis">
        <Grid
          item
          xs={12}
          md={12}
          container
          justify="space-evenly"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={6}
            container
            justify="center"
            alignItems="center"
          >
            <Grid item xs={10} md={10}>
              {/* <Typography variant="overline" color="textPrimary">
                {product.type}
              </Typography>
              <Typography variant="h4" color="primary" gutterBottom>
                <Box style={{ textTransform: "uppercase" }}>
                  <b>{product.name}</b>
                </Box>
              </Typography> */}

              <Typography variant="subtitle1" align="justify">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={product.content}
                  transformImageUri={uri =>
                    uri.startsWith("http")
                      ? uri
                      : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
                  }
                  className="dynamicContent"
                  escapeHtml={false}
                />
              </Typography>

              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={() => {
                  handleOpen()
                  setSelection(product.name)
                }}
              >
                {product.cta === "DEMO" && "TALK TO US"}
                {product.cta === "PREBOOK" && "TALK TO US"}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box overflow="hidden">
              <img
                src={product.image?.publicURL}
                alt={product.name}
                width="100%"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  orderFirst: {
    [theme.breakpoints.up("md")]: {
      order: 1,
    },
  },
  orderSecond: {
    [theme.breakpoints.up("md")]: {
      order: 2,
    },
  },
}))

export default Product
