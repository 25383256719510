import React, { useState, useEffect } from "react"
import { fade, makeStyles, withStyles } from "@material-ui/core/styles"
import {
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
} from "@material-ui/core"
import modal from "../../images/training/modal.jpg"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

const CustomTextField = withStyles(theme => ({
  root: {
    marginTop: 10,
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "rgba(255,255,255,1)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // borderLeft: `5px solid ${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}))(TextField)

function ProductsModal(props) {
  const classes = useStyles()
  const [standard, setStandard] = React.useState(null)
  const [name, setName] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [message, setMessage] = React.useState("")
  const [success, setSuccess] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(false)
  const [endpoint, setEndpoint] = React.useState("products")

  useEffect(() => {
    setSuccess(false)
    setErrorMessage(false)
  }, [props.handleClose])

  const handleClick = e => {
    e.preventDefault()
    if (standard === null) {
      const query = JSON.stringify({
        name: name,
        phone: phone,
        email: email,
        type: props.selection,
        message: message,
        endpoint: endpoint,
      })
      fetch("https://phaidelta.com:3001/leads", {
        headers: { "content-type": "application/json" },
        method: "POST",
        body: query,
      })
        .then(({ data }) => {
          setSuccess(true)
        })
        .catch(err => {
          setErrorMessage(true)
        })
    } else {
      console.log("Something went Wrong")
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <Box
              position="absolute"
              top="20px"
              right="20px"
              onClick={props.handleClose}
            >
              <HighlightOffIcon color="secondary" fontSize="large" />
            </Box>
            {!success ? (
              <>
                <Box py={4} px={10}>
                  <Typography
                    variant="h6"
                    color="secondary"
                    align="center"
                    style={{ opacity: 1, letterSpacing: 5 }}
                  >
                    <b>FOR PRODUCT ENQUIRIES</b>
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  pb={5}
                  maxWidth="250px"
                  margin="auto"
                >
                  <form onSubmit={handleClick}>
                    <CustomTextField
                      InputProps={{ disableUnderline: true }}
                      variant="filled"
                      size="small"
                      label="Name"
                      className={classes.formTextField}
                      fullWidth
                      required
                      onChange={event => {
                        setName(event.target.value)
                      }}
                    />
                    <CustomTextField
                      InputProps={{ disableUnderline: true }}
                      variant="filled"
                      size="small"
                      label="Phone"
                      className={classes.formTextField}
                      fullWidth
                      onChange={event => {
                        setPhone(event.target.value)
                      }}
                    />
                    <CustomTextField
                      InputProps={{ disableUnderline: true }}
                      variant="filled"
                      size="small"
                      label="Email"
                      type="email"
                      className={classes.formTextField}
                      fullWidth
                      required
                      onChange={event => {
                        setEmail(event.target.value)
                      }}
                    />
                    <CustomTextField
                      InputProps={{ disableUnderline: true }}
                      variant="filled"
                      size="small"
                      label="Product"
                      value={props.selection}
                      className={classes.formTextField}
                      fullWidth
                      disabled
                    />
                    <CustomTextField
                      InputProps={{ disableUnderline: true }}
                      variant="filled"
                      size="small"
                      label="Your Message..."
                      multiline
                      rows={3}
                      className={classes.formTextField}
                      fullWidth
                      onChange={event => {
                        setMessage(event.target.value)
                      }}
                    />
                    <TextField
                      id="standard-basic"
                      variant="filled"
                      label="Standard"
                      fullWidth
                      style={{ display: "none" }}
                      value={standard}
                      onChange={event => {
                        setStandard(event.target.value)
                      }}
                    />
                    <Box pt={2} width={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                      >
                        SEND
                      </Button>
                    </Box>
                  </form>
                  {errorMessage && (
                    <Typography
                      variant="subtitle1"
                      color="error"
                      align="center"
                    >
                      Error!
                    </Typography>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box py={3}>
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    align="center"
                  >
                    Thank you for contacting us! We'll get back to you in 48
                    hours.
                  </Typography>
                </Box>
                <Box pt={2} width={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={props.handleClose}
                  >
                    CLOSE
                  </Button>
                </Box>
              </>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "solid 1px white",
    padding: theme.spacing(2, 4, 3),
    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${modal})`,
    backgroundSize: "cover",
    backgroundPosition: "right center",
  },
}))

export default ProductsModal
